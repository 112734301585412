/* eslint-disable react/jsx-no-duplicate-props */
import {
  Avatar,
  Box,
  Divider,
  VStack,
  HStack,
  IconButton,
  Icon,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useRef, useEffect, useState } from 'react'
import { FaRegCheckCircle, FaRegCircle } from 'react-icons/fa'
import { BiMailSend } from 'react-icons/bi'
import { useLanguageContext } from '../../../context/locale.context'
import { sectionDetailedTrack } from '../../../containers/repository/repo.utils'
import { GA_CATEGORY_FILES } from './../../../containers/repository/repo.utils'
import OriginFormSendEmail from '../../../components/origin-form-email-model'

const FormCategorySelector = ({
  onSelect,
  selected,
  list,
  onSelectTag,
  tagsList,
  formList,
  selectedTag,
  selectedTagType,
  onFormSelect,
  setCloseDrawer,
  from
}) => {
  const bottomRef = useRef()
  const {
    state: { locale },
  } = useLanguageContext()

  const [selFormData, setSelFormData] = useState({})

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  useEffect(() => {
    scrollToBottom()
  }, [list, tagsList, formList])

  useEffect(() => {
    scrollToBottom()
  }, [])

  const {
    isOpen: isSendEmail,
    onOpen: onOpenSendEmail,
    onClose: onCloseSendEmail,
  } = useDisclosure()

  useEffect(() => {
   
    if(list?.forms?.length===1&&from==='dashboard'){
      onFormSelect(list.forms[0])
      setCloseDrawer(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list])

  return (
    <>
      <HStack my={4}>
        <VStack
          alignItems="flex-start"
          borderRadius="sm"
          boxShadow="md"
          py={3}
          px={5}
          w="full"
        >
          <Box w="full">
            <Text>
              {locale['Start a new file by selecting a']}{' '}
              <Text as="span" color={localStorage.getItem('color')} fontSize="sm" fontWeight="700">
                {locale['File Type / Category']}
              </Text>{' '}
              {locale['from the list below:']}
            </Text>
            {(list.tag_types || []).map(tagType => {
              const { form_tag_type_id } = tagType
              return (
                <div key={form_tag_type_id}>
                  <HStack
                    my={2}
                    onClick={() => {
                      sectionDetailedTrack({
                        category: GA_CATEGORY_FILES,
                        action: 'Form Category Selector',
                        label: 'Select Category',
                      })
                      onSelect(tagType)
                    }}
                  >
                    <Icon
                      as={
                        tagType.form_tag_type_id ===
                        selectedTagType.form_tag_type_id
                          ? FaRegCheckCircle
                          : FaRegCircle
                      }
                      w={6}
                      h={6}
                      border="brand.900"
                      color="brand.900"
                    />
                    <Text>{tagType.form_tag_type_name}</Text>
                  </HStack>
                  <Divider />
                </div>
              )
            })}
            
            {(list.forms || []).map(form => {
              return (
                <div key={form.form_id}>
                  {/* TODO: onSelect function to b written to open form */}
                  <HStack
                    justifyContent={'space-between'}
                    my={2}
                    cursor="pointer"
                  >
                    <HStack
                      onClick={() => {
                        sectionDetailedTrack({
                          category: GA_CATEGORY_FILES,
                          action: 'Form Category Selector',
                          label: 'Form Select',
                        })
                        onFormSelect(form)
                      }}
                    >
                      <Icon
                        as={selected ? FaRegCheckCircle : FaRegCircle}
                        w={6}
                        h={6}
                        border="brand.900"
                        color="brand.900"
                      />
                      <Text>{form.form_workflow_activity_type_name}</Text>
                    </HStack>
                    {form.activity_type_flag_enable_web_url === 1 && (
                      <IconButton
                        bg="white"
                        borderRadius="md"
                        boxShadow="md"
                        size="sm"
                        _focus={{
                          bg: 'secondary',
                        }}
                        _hover={{
                          bg: 'secondary',
                        }}
                        icon={<Icon as={BiMailSend} w={5} h={5} />}
                        onClick={() => {
                          setSelFormData(form)
                          onOpenSendEmail()
                        }}
                      />
                    )}
                  </HStack>
                  <Divider />
                </div>
              )
            })}

            
           
          </Box>
        </VStack>7
      </HStack>
      {Object.keys(tagsList).length > 0 ? (
        <>
          <HStack my={4}>
            <Box borderRadius="sm" boxShadow="md" p={3} w="full">
            { selectedTagType?.form_tag_type_name && <Text>
                I Chose{' '}
                <Text as="span" fontWeight="800">
                  {selectedTagType.form_tag_type_name || ''}
                </Text>{' '}
              </Text>}
            </Box>
          </HStack>
          <HStack my={4}>
            <VStack
              alignItems="flex-start"
              borderRadius="sm"
              boxShadow="md"
              py={3}
              px={5}
              w="full"
            >
              <Box w="full">
                <Text>
                  {locale['Start a new file by selecting a']}{' '}
                  <Text
                    as="span"
                    color={localStorage.getItem('color')}
                    fontSize="sm"
                    fontWeight="700"
                  >
                    {locale['File Type / Category']}
                  </Text>{' '}
                  {locale['from the list below:']}
                </Text>
                {(tagsList.tags || []).map(tag => {
                  const { form_tag_id } = tag
                  return (
                    <div key={form_tag_id}>
                      <HStack
                        my={2}
                        onClick={() => {
                          sectionDetailedTrack({
                            category: GA_CATEGORY_FILES,
                            action: 'Form Category Selector',
                            label: 'Select Tag',
                          })
                          onSelectTag(tag)
                        }}
                      >
                        <Icon
                          as={
                            form_tag_id === selectedTag.form_tag_id
                              ? FaRegCheckCircle
                              : FaRegCircle
                          }
                          w={6}
                          h={6}
                          border="brand.900"
                          color="brand.900"
                        />
                        <Text>{tag.form_tag_name}</Text>
                      </HStack>
                      <Divider />
                    </div>
                  )
                })}
              </Box>
            </VStack>
          </HStack>
        </>
      ) : null}

      {Object.keys(formList).length > 0 ? (
        <>
          <HStack my={4}>
            <Box borderRadius="sm" boxShadow="md" p={3} w="full">
              <Text>
                I Chose{' '}
                <Text as="span" fontWeight="800">
                  {selectedTag.form_tag_name || selectedTagType?.form_tag_type_name}
                </Text>{' '}
              </Text>
            </Box>
          </HStack>
          <HStack my={4}>
            <VStack
              alignItems="flex-start"
              borderRadius="sm"
              boxShadow="md"
              py={3}
              px={5}
              w="full"
            >
              <Box w="full">
                <Text>
                  {locale['Start a new file by selecting a']}{' '}
                  <Text
                    as="span"
                    color={localStorage.getItem('color')}
                    fontSize="sm"
                    fontWeight="700"
                  >
                    {locale['File Type / Category']}
                  </Text>{' '}
                  {locale['from the list below:']}
                </Text>
                {(formList.forms || []).map(form => {
                  return (
                    <div key={form.form_id}>
                      <HStack
                        justifyContent={'space-between'}
                        my={2}
                        cursor="pointer"
                      >
                        <HStack
                          onClick={() => {
                            sectionDetailedTrack({
                              category: GA_CATEGORY_FILES,
                              action: 'Form Category Selector',
                              label: 'Form Select',
                            })
                            onFormSelect(form)
                          }}
                        >
                          <Icon
                            as={selected ? FaRegCheckCircle : FaRegCircle}
                            w={6}
                            h={6}
                            border="brand.900"
                            color="brand.900"
                          />
                          <Text>{form.form_workflow_activity_type_name}</Text>
                        </HStack>
                        {form.activity_type_flag_enable_web_url === 1 && (
                          <IconButton
                            bg="white"
                            borderRadius="md"
                            boxShadow="md"
                            size="sm"
                            _focus={{
                              bg: 'secondary',
                            }}
                            _hover={{
                              bg: 'secondary',
                            }}
                            icon={<Icon as={BiMailSend} w={5} h={5} />}
                            onClick={() => {
                              setSelFormData(form)
                              onOpenSendEmail()
                            }}
                          />
                        )}
                      </HStack>
                      <Divider />
                    </div>
                  )
                })}
              </Box>
            </VStack>
          </HStack>
        </>
      ) : null}
      <div ref={bottomRef}></div>
      {Object.keys(selFormData).length && !!isSendEmail ? (
        <OriginFormSendEmail
          isOpen={isSendEmail}
          onClose={onCloseSendEmail}
          activity={{}}
          form={selFormData}
        />
      ) : null}
    </>
  )
}

export default FormCategorySelector
