/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import FieldItem from '../fieldItem'
import {
	Text,
	Box,
	useDisclosure,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	DrawerFooter,
	Button,
	HStack,
	Divider,
	Flex,
} from '@chakra-ui/react'
import {
	accountCode,
	loadFinalValue,
	prepareFinalFieldValueForEdit,
} from '../../../utils/form.util'
import { formatDDMMYYYYHHMM } from '../../../utils/date.utils'
import {
	useFetchAutoPopulateLov,
	useValidateGroupAccountName,
} from '../../../hooks/form.hooks'
import { useSmartFormContext } from '../../../context/smart-form.context'
import { sharedConst, smartFormConst } from '../../../utils/action.constant'
import { useAuthContext } from '../../../context/auth.context'
import AlertBox from '../../AlertBox'
import {
	dataLayerTagManager,
	GA_CATEGORY_FORM_MANAGEMENT,
	sectionDetailedTrack,
} from '../../../containers/repository/repo.utils'
import { useLanguageContext } from '../../../context/locale.context'
import { useSharedContext } from '../../../context/shared.context'

const FormFieldList = ({
	fieldList,
	isSmartForm,
	onShowAction,
	showFormAction,
	accountCheckOn,
	formData,
	formAddFly,
	isOnFly,
	enableSubmit,
	setEnableSubmit,
	isFromEmail = false,
	setCheckValidate = () => {},
	setSmartFieldListSmart = () => {},
	scrollToBottom = () => {},
	onShowDescription = () => {},
	setworkspaceMode,
	onCloseFormPanel,
	isOpenFormPanel,
	onClose,
	...rest
}) => {
	const [currentField, setcurrentField] = useState(null)
	const [accountError, setaccountError] = useState(null)
	const [isSkip, setSkip] = useState(true)
	const [smartFieldList, setSmartFieldList] = useState([])
	const [count, setCount] = useState(0)
	const { mutate: mutateAccountNameValidate } = useValidateGroupAccountName()
	const { isOpen: isAlertOpen, onOpen, onClose: alertClose } = useDisclosure()
	const [alertMessage, setAlertMessage] = useState('')
	const { mutateAsync: mutateloadPrefillField, isLoading } =
		useFetchAutoPopulateLov()
	const [fileUpload, setFileUplaod] = useState(false)
	const [isFieldValidate, setIsFieldValidate] = useState(false)
	const [isDesignation, setDesignation] = useState(false)
	const [selectedValue, setSelectedValue] = useState(null)
	const [showFooterSectionChild, setFooterSectionChild] = useState(false)
	const [isSubmitSection, setIsSubmitSection] = useState(false)
	const [onClickFunctionsChild, setOnClickFunctionsChild] = useState({
		skipFunction: () => {},
		saveDravtFunction: () => {},
		nextFunction: () => {},
	})
	const [footerConditionConstants, setFooterConditionConstants] = useState({
		isRequired: null,
		isDisabledCondition: null,
		showSaveDraft: null,
	})
	const {
		state: { fields },
		dispatch,
	} = useSmartFormContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { dispatch: dispatchSharedContext } = useSharedContext()
	const {
		state: {
			authData: { organization_flag_enable_grid },
		},
	} = useAuthContext()

	const lastFieldRef = useRef()

	const scrollToLastField = () => {
		setTimeout(() => {
			lastFieldRef?.current?.scrollIntoView({ behavior: 'smooth' })
		}, 0)
	}

	useEffect(() => {
		if (fieldList && !!fieldList.length) {
			let cField = fieldList[0]
			let skipList = rest?.skipFieldList
			if (!!cField && !!rest.isBulkEdit) {
				setSmartFieldList(fieldList)
				setcurrentField(cField)
			} else if (!!cField && !!rest.refillView) {
				let { form_id } = cField
				let refillFields =
					!!rest.refillData && !!rest.refillData[form_id]
						? Object.keys(rest.refillData[form_id])
						: []

				let fieldRefill = fieldList.map(f => {
					if (
						refillFields.includes(`${f.field_id}`) ||
						f.field_mandatory_enabled === 0
					) {
						return f
					}
					return ''
				})

				setSmartFieldList(fieldRefill)
				setcurrentField(fieldRefill[fieldRefill.length - 1])
				onShowAction(true)
			} else if (!!skipList && skipList?.length) {
				renderNextField(skipList, cField, [cField])
			} else if (
				!!cField &&
				rest?.form_flag_enable_external_link === 2 &&
				isFromEmail
			) {
				setSmartFieldList(fieldList)
				setcurrentField(cField)
			} else {
				setSmartFieldList([cField])
				setcurrentField(cField)
			}
		}
	}, [fieldList, rest?.skipFieldList, rest?.refillData])

	useEffect(() => {
		setSmartFieldListSmart(smartFieldList)
	}, [smartFieldList])

	useEffect(() => {
		if (
			(!!showFormAction || !!rest.isBulkEdit) &&
			fieldList &&
			fieldList.length
		) {
			setIsSubmitSection(true)
			setFooterSectionChild(false)
		}
	}, [showFormAction, rest.isBulkEdit, fieldList, fieldList.length])

	useEffect(() => {
		if (!showFooterSectionChild && !isSubmitSection) {
			setIsSubmitSection(false)
		}
	}, [showFooterSectionChild])

	const renderNextField = (list, cField, finalFields = []) => {
		let isSkipField = list.includes(cField.field_id)
		if (isSkipField) {
			let skipField =
				!!fields[cField.form_id] && !!fields[cField.form_id][cField.field_id]
					? fields[cField.form_id][cField.field_id]
					: null
			if (!!skipField && skipField.final.field_data_type_id === 33) {
				let f = fieldList.filter(f => f.field_id === cField.field_id)
				let options = !!f && f[0]?.options
				if (!!options) {
					let s_opt = options.filter(
						c => c.data_type_combo_id === skipField.final.data_type_combo_id
					)
					if (!!s_opt) {
						if (s_opt[0].comboNextFieldId === 0) {
							const ci1 = fieldList.findIndex(
								f => f.field_id === cField.field_id
							)
							const cFieldN1 = fieldList[ci1 + 1]
							if (!!cFieldN1) {
								let isSkipField = list.includes(cFieldN1.field_id)
								if (isSkipField) {
									finalFields = [...finalFields, cFieldN1]
									renderNextField(list, cFieldN1, finalFields)
								} else {
									setSmartFieldList(finalFields)
									setcurrentField(cField)
									if (
										cField.next_field_id === -1 ||
										(!isSmartForm && finalFields.length === fieldList.length)
									) {
										onShowAction(true)
									}
								}
							} else {
								setSmartFieldList(finalFields)
								setcurrentField(cField)
								if (
									cField.next_field_id === -1 ||
									(!isSmartForm && finalFields.length === fieldList.length)
								) {
									onShowAction(true)
								}
							}
						} else if (s_opt[0].comboNextFieldId === -1) {
							setSmartFieldList(finalFields)
							setcurrentField(cField)
							onShowAction(true)
						} else {
							let nxtf = fieldList.filter(
								f => f.field_id === s_opt[0].comboNextFieldId
							)
							if (!!nxtf && !!nxtf[0]) {
								let isSkipField = list.includes(nxtf[0].field_id)
								if (isSkipField) {
									finalFields = [...finalFields, ...nxtf]
									renderNextField(list, nxtf[0], finalFields)
								} else {
									setSmartFieldList(finalFields)
									setcurrentField(cField)
									if (
										cField.next_field_id === -1 ||
										(!isSmartForm && finalFields.length === fieldList.length)
									) {
										onShowAction(true)
									}
								}
							} else {
								setSmartFieldList(finalFields)
								setcurrentField(cField)
								if (
									cField.next_field_id === -1 ||
									(!isSmartForm && finalFields.length === fieldList.length)
								) {
									onShowAction(true)
								}
							}
						}
					}
				}
			} else {
				let cNxtField = !!isSmartForm
					? fieldList.find(f => f.field_id === cField.next_field_id)
					: false
				if (!!isSmartForm && !!cNxtField) {
					let isSkipField = list.includes(cNxtField.field_id)
					if (isSkipField) {
						finalFields = [...finalFields, cNxtField]
						renderNextField(list, cNxtField, finalFields)
					} else {
						if (finalFields.length < list.length && !!cNxtField) {
							finalFields = [...finalFields, cNxtField]
							renderNextField(list, cNxtField, finalFields)
						} else {
							setSmartFieldList(finalFields)
							setcurrentField(cField)
							if (
								cField.next_field_id === -1 ||
								(!isSmartForm && finalFields.length === fieldList.length)
							) {
								onShowAction(true)
							}
						}
					}
				} else {
					const ci = fieldList.findIndex(f => f.field_id === cField.field_id)
					const cFieldN = fieldList[ci + 1]
					if (!!cFieldN) {
						let isSkipField = list.includes(cFieldN.field_id)
						if (isSkipField) {
							finalFields = [...finalFields, cFieldN]
							renderNextField(list, cFieldN, finalFields)
						} else {
							if ([21, 17].includes(cFieldN.data_type_id)) {
								finalFields = [...finalFields, cFieldN]
								renderNextField(list, cFieldN, finalFields)
							} else {
								setSmartFieldList(finalFields)
								setcurrentField(cField)
								if (
									cField.next_field_id === -1 ||
									(!isSmartForm && finalFields.length === fieldList.length)
								) {
									onShowAction(true)
								}
							}
						}
					} else {
						setSmartFieldList(finalFields)
						setcurrentField(cField)
						if (
							cField.next_field_id === -1 ||
							(!isSmartForm && finalFields.length === fieldList.length)
						) {
							onShowAction(true)
						}
					}
				}
			}
		} else {
			if (finalFields.length < list.length) {
				let cNxtField = !!isSmartForm
					? fieldList.find(f => f.field_id === cField.next_field_id)
					: false
				if (!!isSmartForm && !!cNxtField) {
					finalFields = [...finalFields, cNxtField]
					renderNextField(list, cNxtField, finalFields)
				} else {
					const ci2 = fieldList.findIndex(f => f.field_id === cField.field_id)
					const cFieldN2 = fieldList[ci2 + 1]
					if (!!cFieldN2) {
						finalFields = [...finalFields, cFieldN2]
						renderNextField(list, cFieldN2, finalFields)
					} else {
						setSmartFieldList(finalFields)
						setcurrentField(cField)
						if (
							cField.next_field_id === -1 ||
							(!isSmartForm && finalFields.length === fieldList.length)
						) {
							onShowAction(true)
						}
					}
				}
			} else {
				setSmartFieldList(finalFields)
				setcurrentField(cField)
				if (
					cField.next_field_id === -1 ||
					(!isSmartForm && finalFields.length === fieldList.length)
				) {
					onShowAction(true)
				}
			}
		}
		scrollToLastField()
	}

	// useEffect(() => {
	//   if (fieldList && !!fieldList.length) {
	//     let cField = fieldList[0];
	//     if (!!cField) {
	//       setSmartFieldList([cField]);
	//       setcurrentField(cField);
	//     }
	//   }
	// }, []);

	useEffect(() => {
		if (!!currentField && Object.keys(currentField).length && isSkip) {
			handleLabelField(currentField)
		}
	}, [currentField])

	const handleLabelField = async cField => {
		if (cField.field_value_prefill_enabled === 1) {
			const { field_id, form_id, field_inline_data } = cField

			let filedId = field_inline_data?.dependent_field_id
			let activityId
			let assetId
			if (!!filedId) {
				let field = fields?.[form_id]?.[filedId]
				activityId = field?.field_value?.details?.activity_id
				assetId = field?.field_value?.details?.asset_id
				if (!!activityId) {
					mutateloadPrefillField(
						{
							form_id: cField.form_id,
							field_id: cField.field_id,
							dependent_workflow_activity_id: activityId,
							entity_id: 0,
						},
						{
							onSuccess: async res => {
								if (!!res && res.length) {
									let fieldValue = {
										...cField,
										field_data_type_id: cField.data_type_id,
										field_value: res,
									}
									dispatch({
										type: smartFormConst.SET_FIELD_VALUE,
										fieldId: cField.field_id,
										formId: cField.form_id,
										value: loadFinalValue(
											cField,
											prepareFinalFieldValueForEdit(fieldValue, cField)
										),
									})
								}
							},
						}
					)
				}
				if (!!assetId) {
					await mutateloadPrefillField(
						{
							form_id: cField.form_id,
							field_id: cField.field_id,
							dependent_workflow_activity_id: 0,
							entity_id: assetId,
						},
						{
							onSuccess: async res => {
								console.log('outputres', res)
								if (!!res && res.length) {
									let fieldValue = {
										...cField,
										field_data_type_id: cField.data_type_id,
										field_value: res,
									}
									dispatch({
										type: smartFormConst.SET_FIELD_VALUE,
										fieldId: cField.field_id,
										formId: cField.form_id,
										value: loadFinalValue(
											cField,
											prepareFinalFieldValueForEdit(fieldValue, cField)
										),
									})
								}
							},
						}
					)
				}
			}
		}

		let refillItem =
			!!rest.refillData &&
			Object.keys(rest.refillData).length &&
			rest.refillData[cField.form_id] &&
			rest.refillData[cField.form_id][cField.field_id]
		let fieldItem =
			!!rest.autoPopuateList &&
			rest.autoPopuateList.find(value => !!value[cField.field_id])
		if (!!fieldItem) {
			let fieldValue = {
				...cField,
				field_data_type_id: cField.data_type_id,
				field_value: fieldItem[cField.field_id],
			}
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: cField.field_id,
				formId: cField.form_id,
				value: loadFinalValue(
					cField,
					prepareFinalFieldValueForEdit(fieldValue, cField)
				),
			})
		} else if (!!refillItem) {
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: cField.field_id,
				formId: cField.form_id,
				value: refillItem,
			})
		}
		if (
			!!cField.data_type_id &&
			!!isSmartForm &&
			organization_flag_enable_grid === 1
		) {
			let select_options =
				cField.data_type_id === 33
					? [
							...new Set(
								cField.options.map(c => c.comboNextFieldId).filter(Boolean)
							),
					  ]
					: [0]
			let prefill =
				!!cField.field_value_prefill_enabled &&
				cField.field_value_prefill_enabled === 1
			if (
				(smartFieldList.length < fieldList.length &&
					select_options?.length === 1) ||
				(!prefill && cField.data_type_id === 33)
			) {
				if (cField.data_type_id === 33) {
					if (select_options.includes(0)) {
						onContinue(cField.next_field_id)
					}
				} else if (![57, 59, 21].includes(cField.data_type_id)) {
					onContinue(cField.next_field_id)
				}
			} else if (smartFieldList.length === fieldList.length) {
				onShowAction(true)
			}
		} else if (
			cField.data_type_id &&
			!isSmartForm &&
			organization_flag_enable_grid === 1
		) {
			onContinue(cField.next_field_id)
		}
		if (cField?.field_inline_data?.field_skip_condition) {
			let { data_type_combo_id, field_id } =
				cField.field_inline_data.field_skip_condition
			let skipComboId =
				!!fields[cField.form_id] && !!fields[cField.form_id][field_id]
					? fields[cField.form_id][field_id]?.final?.data_type_combo_id
					: null
			if (data_type_combo_id === skipComboId) {
				setCount(count => count + 1)
				onContinue(cField.next_field_id)
			}
		}
		if (!!cField && [21, 17].includes(cField.field_data_type_id)) {
			onContinue(cField.next_field_id)
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: cField.field_id,
				formId: cField.form_id,
				value: {
					final: {
						form_id: cField.form_id,
						field_id: cField.field_id,
						field_reference_id: cField.field_reference_id,
						field_name: cField.field_name,
						field_data_type_id: 0,
						field_data_type_category_id: 0,
						data_type_combo_id: '0',
						message_unique_id: 0,
						field_value: '',
					},
					field_value: '',
					is_skip: true,
				},
			})
		}
	}

	const toNextField = nextFieldId => {
		/**
		 * NOTE: make sure smart form end fields should have nextFieldId as -1 or no field with 0
		 */
		if (String(Number(nextFieldId)) === 'NaN') {
			onShowAction(true)
			setcurrentField({})
			return
		}
		if (
			nextFieldId === -1 ||
			(!isSmartForm && smartFieldList.length === fieldList.length)
		) {
			onShowAction(true)
			setcurrentField({})
			return
		}
		let cField = !!isSmartForm
			? fieldList.find(f => f.field_id === nextFieldId)
			: false
		if (!!isSmartForm && !!cField) {
			setSmartFieldList([...smartFieldList, cField])
			if (organization_flag_enable_grid === 1) {
				// setTimeout(() => {
				setcurrentField(cField)
				// }, 500)
			} else {
				setcurrentField(cField)
			}
		} else if (!!isSmartForm && !cField) {
			setAlertMessage(
				'Seems there is an issue with form. Please reach out to your administrator to resolve this.'
			)
			onOpen()
			return false
		} else {
			const ci = fieldList.findIndex(f => f.field_id === currentField.field_id)
			const cField = fieldList[ci + 1]
			if (!!cField) {
				setcurrentField(cField)
				setSmartFieldList([...smartFieldList, cField])
			} else {
				onShowAction(true)
				setcurrentField({})
				return
			}
		}
		if (organization_flag_enable_grid !== 1) {
			scrollToLastField()
		}
		setSkip(true)
	}

	const onContinue = (nextFieldId, isset = false) => {
		let isSkipField =
			!!rest?.skipFieldList && rest?.skipFieldList.includes(nextFieldId)
		if (!isSkipField || !!isset) toNextField(nextFieldId)
		scrollToLastField()
		return
		// if (
		//   smartFieldList.length === 1 &&
		//   accountCheckOn === accountCode.ACCOUNT_NAME
		// ) {
		//   // TODO: handle account code validation
		//   let firstField = smartFieldList[0];
		//   let title;
		//   if ([19, 20].includes(firstField.field_id)) {
		//     title = fields[firstField.field_id];
		//   } else {
		//     title = `${formData.form_name}-${formatDDMMYYYYHHMM()}`;
		//   }
		// mutateAccountNameValidate(
		//   {
		//     activity_title: title,
		//     activity_type_id: formData.form_workflow_activity_type_id,
		//   },
		//   {
		//     onSuccess: async data => {
		//       if (data.isError) {
		//         setaccountError(data.message);
		//       } else {
		//         console.log('data');
		//         setaccountError(null);
		//         toNextField(nextFieldId);
		//         toNextField(nextFieldId);
		//         // TODO: Use the generated_group_account_code while save
		//       }
		//     },
		//     onError: async err => {
		//       console.log('error for logging of check name');
		//     },
		//   }
		// );
		// } else {
		//   toNextField(nextFieldId);
		// }
	}

	const onPrevious = index => {
		// TODO show the modal to user wether he want to override the values.
		const removeStrings = smartFieldList.filter(Boolean)
		const nonSkipFields = smartFieldList.slice(index, smartFieldList.length)
		const skippedFieldList = removeStrings.slice(0, index + 1)

		setcurrentField(skippedFieldList[index])
		setSmartFieldList(skippedFieldList)
		setSkip(false)
		let submittedValues = !!fields?.[smartFieldList[0].form_id]
			? Object.keys(fields[smartFieldList[0].form_id])
			: []
		let finalRevertedValues = {}
		Object.keys(fields).forEach(k => {
			if (Number(k) !== Number(smartFieldList[0].form_id)) {
				finalRevertedValues[k] = fields[k]
			}
		})
		finalRevertedValues[smartFieldList[0].form_id] = {}
		submittedValues.forEach(fId => {
			let isExist = skippedFieldList.find(i => i.field_id === Number(fId))
			let isEventData = nonSkipFields.find(
				i => i.field_id === Number(fId) && i.data_type_id === 77
			)
			if (isExist && !isEventData) {
				finalRevertedValues[smartFieldList[0].form_id][fId] =
					fields[smartFieldList[0].form_id][fId]
			} else if (isEventData) {
				finalRevertedValues[smartFieldList[0].form_id][fId] = {
					...fields[smartFieldList[0].form_id][fId],
					field_value: {
						...fields[smartFieldList[0].form_id][fId].field_value,
						isFromEdit: true,
					},
				}
			}
		})
		dispatch({
			type: smartFormConst.SET_FIELD_VALUES,
			formId: smartFieldList[0].form_id,
			fields: finalRevertedValues,
		})
		if (!!showFormAction) {
			onShowAction(false)
		}
	}

	// useEffect(() => {
	// 	setworkspaceMode('STATUS_LIFECYCLE')
	// }, [])
	const OnClosePanel = () => {
		//onCloseFormPanel()
		rest.onToggleConfirm()
		//setworkspaceMode('STATUS_LIFECYCLE')
	}
	const onValidateAndContinue = () => {
		setDesignation(true)
		//setError(false) //check
		// Do the field value validation here.
		// TODO: next fieldId will be changes in case of select
		if (selectedValue) {
			onContinue(selectedValue.comboNextFieldId, true)
		} else {
			// onContinue(field.next_field_id, true) //check
		}
	}

	return (
		<>
			{/* <Drawer
				isOpen={isOpenFormPanel}
				placement='right'
				onClose={OnClosePanel}
				size='lg'
			>
				<DrawerOverlay />
				<DrawerContent
					bg='#f5f5f5'
					backgroundImage={`url(${bgImage})`}
					fontFamily={'Avenir-Regular'}
				>
					<DrawerCloseButton />
					<DrawerHeader borderBottomWidth='1px'>
						{formData?.form_name}
					</DrawerHeader> */}

			{smartFieldList && !!smartFieldList.length ? (
				(smartFieldList || []).filter(Boolean).map((field, index) => {
					return (
						<FieldItem
							activity={rest.activity}
							current={currentField}
							field={field}
							fieldList={fieldList}
							fieldIndex={index}
							key={field.field_id}
							onContinue={onContinue}
							onPrevious={onPrevious}
							showFieldAction={showFormAction}
							accountError={accountError}
							onFormAddFly={formAddFly}
							workforceForms={rest.workforceForms}
							isOnFly={isOnFly}
							isBulkEdit={rest.isBulkEdit}
							onSaveDraft={rest.onSaveDraft}
							activity_type_flag_enable_draft={
								formData.activity_type_flag_enable_draft
							}
							skipFieldList={rest?.skipFieldList}
							refillView={rest.refillView}
							setEnableSubmit={setEnableSubmit}
							isSmartForm={isSmartForm}
							setCheckValidate={setCheckValidate}
							scrollToBottom={scrollToBottom}
							onShowDescription={onShowDescription}
							setFileUplaod={setFileUplaod}
							fileUpload={fileUpload}
							setIsFieldValidate={setIsFieldValidate}
							isFieldValidate={isFieldValidate}
							isDesignation={isDesignation}
							setDesignation={setDesignation}
							setSelectedValue={setSelectedValue}
							selectedValue={selectedValue}
							onClose={onClose}
							setFooterSectionChild={setFooterSectionChild}
							setOnClickFunctionsChild={setOnClickFunctionsChild}
							setFooterConditionConstants={setFooterConditionConstants}
							isSubmitSection={isSubmitSection}
							setIsSubmitSection={setIsSubmitSection}
						/>
					)
				})
			) : (
				<Text>No details found!</Text>
			)}

			{/* <DrawerFooter borderTopWidth='1px'>
						{/* <Button variant='outline' mr={3} onClick={onCloseFormPanel}>
							Cancel
						</Button> */}
			{/* 						
							//field &&
							//currentField &&
							//!rest.isBulkEdit &&
							//field.field_id === current.field_id && */}
			{/*!fileUpload &&
							!showFormAction ? (
								<Box justifySelf='flex-end'>
									{/* {!isRequired ? ( */}
			{/*<Button
										variant='link'
										colorScheme={localStorage.getItem('color')}
										size='sm'
										onClick={() => {
											sectionDetailedTrack({
												category: GA_CATEGORY_FORM_MANAGEMENT,
												action: 'Field Item',
												label: 'Skip Item',
											})
											//onSkip()
										}}
									>
										{'Skip'}
									</Button>
									{/* ) : null} */}
			{/*<Button
										// className={`field-item-continue-button-${toSnakeCase(
										// 	field.field_name
										// )}`}
										variant='link'
										colorScheme={localStorage.getItem('color')}
										size='sm'
										ml={2}
										mr={2}
										//disabled={!isFieldValidate || (isRequired && isDelete)}
										onClick={() => {
											sectionDetailedTrack({
												category: GA_CATEGORY_FORM_MANAGEMENT,
												action: 'Field Item',
												label: 'Validating',
											})
											onValidateAndContinue()
										}}
									>
										{locale['Continue']}
									</Button>
									{formData.activity_type_flag_enable_draft === 1 &&
										!rest.refillView && (
											<Button
												// className={`field-item-draft-button-${toSnakeCase(
												// 	field.field_name
												// )}`}
												variant='link'
												colorScheme='red'
												size='sm'
												//disabled={!isFieldValidate || (isRequired && isDelete)}
												onClick={e => {
													sectionDetailedTrack({
														category: GA_CATEGORY_FORM_MANAGEMENT,
														action: 'Field Item',
														label: 'Save As Draft',
													})
													dataLayerTagManager('button_click', {
														viewName: 'Form',
														buttonName: 'Save as Draft',
													})
													rest.onSaveDraft(e)
												}}
											>
												{locale['Save Draft']}
											</Button>
										)}
								</Box>
							) : null
						}
						<Button colorScheme='blue'>Submit</Button>
					</DrawerFooter> */}

			<Box>
				{(!!showFormAction || !!rest.isBulkEdit) &&
				fieldList &&
				fieldList.length &&
				!rest.isOrigin &&
				!rest.refillView ? (
					<Box mt={2} w='full'>
						{/* <Divider /> */}
						{/* <HStack w='full' justifyContent='flex-end' p={2}>
							<Button
								variant='outline'
								size='md'
								borderRadius='sm'
								colorScheme={localStorage.getItem('color')}
								//bg={localStorage.getItem('color')}
								w='140px'
								onClick={() => {
									sectionDetailedTrack({
										category: GA_CATEGORY_FORM_MANAGEMENT,
										action: 'Smart Form Panel',
										label: 'Cancel',
									})
									dataLayerTagManager('button_click', {
										viewName: 'Form',
										buttonName: 'Cancel',
									})
									rest.onToggleConfirm()
								}}
							>
								{locale['Cancel']}
							</Button>
							<Button
								disabled={rest.enableSubmit}
								variant='solid'
								size='md'
								borderRadius='sm'
								colorScheme={localStorage.getItem('color')}
								bg={'#009b76'}
								w='140px'
								ml={1}
								onClick={async () => {
									sectionDetailedTrack({
										category: GA_CATEGORY_FORM_MANAGEMENT,
										action: 'Smart Form Panel',
										label: 'Submit',
									})
									dataLayerTagManager('button_click', {
										viewName: 'Form',
										buttonName: 'Submit',
									})
									rest.onSave().then(() => {
										setTimeout(() => {
											dispatchSharedContext({
												type: sharedConst.REFRESH_LISTING,
											})
										}, 500)
										setTimeout(() => {
											dispatchSharedContext({
												type: sharedConst.FORM_SUBMIT,
											})
										}, 700)
									})
								}}
							>
								{locale['Submit']}
							</Button>
							{!rest.isOrigin &&
								!rest.isBulkEdit &&
								!rest.refillView &&
								formData.activity_type_flag_enable_draft === 1 &&
								// <Button
								// 	disabled={rest.enableSubmit}
								// 	variant='solid'
								// 	size='md'
								// 	borderRadius='sm'
								// 	colorScheme={localStorage.getItem('color')}
								// 	bg={'#009b76'}
								// 	w='140px'
								// 	ml={1}
								// 	onClick={() => {
								// 		sectionDetailedTrack({
								// 			category: GA_CATEGORY_FORM_MANAGEMENT,
								// 			action: 'Smart Form Panel',
								// 			label: 'Save Draft',
								// 		})
								// 		dataLayerTagManager('button_click', {
								// 			viewName: 'Form',
								// 			buttonName: 'Save as Draft',
								// 		})
								// 		rest.onSaveDraft()
								// 	}}
								// >
								// 	{locale['Save as Draft']}
								// </Button>
								''}
						</HStack> */}
					</Box>
				) : showFooterSectionChild ? (
					''
				) : // <Box
				// 	justifySelf='flex-end'
				// 	// mt='calc(100% - 150px)'
				// 	width='100%'
				// 	display={'flex'}
				// 	flexDirection={'column'}
				// 	justifyContent={'flex-end'}
				// >
				// 	{/* <Divider /> */}
				// 	<Box
				// 		display={'flex'}
				// 		flexDirection={'row'}
				// 		mt='20px'
				// 		justifyContent={'flex-end'}
				// 	>
				// 		{!footerConditionConstants?.isRequired ? (
				// 			<ButtonCTA
				// 				variant='sidebarButton'
				// 				children={'Skip'}
				// 				bg='white'
				// 				color='#009B76'
				// 				w='80px'
				// 				h='36px'
				// 				fontSize='14px'
				// 				fontWeight='500'
				// 				onClick={() => {
				// 					onClickFunctionsChild.skipFunction()
				// 				}}
				// 				_hover={{
				// 					bg: '#009B76',
				// 					color: 'white',
				// 				}}
				// 				mr='5px'
				// 			/>
				// 		) : null}
				// 		<ButtonCTA
				// 			variant='sidebarButton'
				// 			children={'Next'}
				// 			bg='white'
				// 			color='#009B76'
				// 			w='80px'
				// 			h='36px'
				// 			mt={6}
				// 			fontSize='14px'
				// 			fontWeight='500'
				// 			onClick={() => {
				// 				onClickFunctionsChild.nextFunction()
				// 			}}
				// 			_hover={{
				// 				bg: '#009B76',
				// 				color: 'white',
				// 			}}
				// 			mr='5px'
				// 			isDisabled={footerConditionConstants?.isDisabledCondition}
				// 		/>
				// 		{footerConditionConstants?.showSaveDraft && (
				// 			<ButtonCTA
				// 				variant='sidebarButton'
				// 				children={'Save Draft'}
				// 				bg='white'
				// 				color='#009B76'
				// 				w='80px'
				// 				h='36px'
				// 				fontSize='14px'
				// 				fontWeight='500'
				// 				onClick={() => {
				// 					sectionDetailedTrack({
				// 						category: GA_CATEGORY_FORM_MANAGEMENT,
				// 						action: 'Field Item',
				// 						label: 'Save As Draft',
				// 					})
				// 					dataLayerTagManager('button_click', {
				// 						viewName: 'Form',
				// 						buttonName: 'Save as Draft',
				// 					})
				// 					// eslint-disable-next-line no-undef
				// 					onSaveDraft(e)
				// 				}}
				// 				_hover={{
				// 					bg: '#009B76',
				// 					color: 'white',
				// 				}}
				// 				mr='5px'
				// 				isDisabled={footerConditionConstants?.isDisabledCondition}
				// 			/>
				// 		)}
				// 	</Box>
				// </Box>
				null}
			</Box>
			{/* {showFooterSectionChild && (
						<DrawerFooter>
						
						</DrawerFooter>
					)}

					{(!!showFormAction || !!rest.isBulkEdit) &&
					fieldList &&
					fieldList.length ? (
						<DrawerFooter>
						
						</DrawerFooter>
					) : null} */}
			{/* </DrawerContent>
			</Drawer> */}

			<Box ref={lastFieldRef}></Box>
			<AlertBox
				isOpen={isAlertOpen}
				onClose={alertClose}
				Message={alertMessage}
			/>
		</>
	)
}

export default FormFieldList
